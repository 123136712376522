<template>
  <div>
    <div class="menu menu-desktop">
      <p class="menu-label">{{ $t('staff.label') }}</p>
      <p class="menu-label">UC</p>
      <scrollactive>
        <ul class="menu-list">
          <li class="is-medium">
            <router-link to="/explore/teachers">
              {{ $t('staff.teaching') }}
            </router-link>
          </li>
          <li class="is-medium">
            <router-link to="/explore/researchers">
              {{ $t('staff.researchers') }}
            </router-link>
          </li>
          <li class="is-medium">
            <router-link to="/explore/researchers-dl57">
              {{ $t('staff.researchers57') }}
            </router-link>
          </li>
          <li class="is-medium">
            <router-link to="/explore/scholarships">
              {{ $t('staff.scholarship') }}
            </router-link>
          </li>
          <li class="is-medium">
            <router-link to="/explore/staff">
              {{ $t('staff.nonTeaching') }}
            </router-link>
          </li>
          <li class="is-medium">
            <router-link to="/explore/leaders">
              {{ $t('staff.administration') }}
            </router-link>
          </li>
        </ul>
      </scrollactive>
      <p class="menu-label">SASUC</p>
      <scrollactive>
        <ul class="menu-list">
          <li class="is-medium">
            <router-link :to="{ path: '/explore/staff', query: { org: 'sasuc' } }">
              {{ $t('staff.nonTeaching') }}
            </router-link>
          </li>
        </ul>
      </scrollactive>
    </div>
    <div class="menu menu-mobile">
      <b-dropdown v-model="navigation" aria-role="list" position="is-bottom-left">
        <button slot="trigger" class="button menu-label" type="button">
          {{ $t('staff.label') }}
          <faicon icon="chevron-down" size="xs" />
        </button>
        <b-dropdown-item custom aria-role="menuitem">
          UC
        </b-dropdown-item>
        <b-dropdown-item has-link value="teachers" aria-role="menuitem">
          <router-link to="/explore/teachers">
            {{ $t('staff.teaching') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item has-link value="researchers" aria-role="menuitem">
          <router-link to="/explore/researchers">
            {{ $t('staff.researchers') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item has-link value="researchers-dl57" aria-role="menuitem">
          <router-link to="/explore/researchers-dl57">
            {{ $t('staff.researchers57') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item has-link value="scholarships" aria-role="menuitem">
          <router-link to="/explore/scholarships">
            {{ $t('staff.scholarship') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item has-link value="staff" aria-role="menuitem">
          <router-link to="/explore/staff">
            {{ $t('staff.nonTeaching') }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item has-link value="leaders" aria-role="menuitem">
          <router-link to="/explore/leaders">
            {{ $t('staff.administration') }}
          </router-link>
        </b-dropdown-item>
        <hr class="dropdown-divider" aria-role="menuitem" />
        <b-dropdown-item custom aria-role="menuitem">
          SASUC
        </b-dropdown-item>
        <b-dropdown-item has-link value="staff" aria-role="menuitem">
          <router-link :to="{ path: '/explore/staff', query: { org: 'sasuc' } }">
            {{ $t('staff.nonTeaching') }}
          </router-link>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navigation: 'teachers',
    }
  },
}
</script>

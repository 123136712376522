<template>
  <div class="record">
    <div class="record-icon">
      <faicon icon="cubes"></faicon>
    </div>
    <div class="record-content">
      <div class="record-header is-flex">
        <span>{{ item.code }}</span>
        <span v-if="getEndDayKey() == 'today'" class="has-text-weight-bold has-text-danger">{{
          $t('applicationState.endsToday')
        }}</span>
        <span v-if="getEndDayKey() == 'tomorrow'" class="has-text-weight-bold">{{
          $t('applicationState.endsTomorrow')
        }}</span>
        <span v-if="item.status == 'published' && getEndDayKey() == 'other' && !willStartSoon && item.publish_end">
          {{ $t('applicationState.endsIn') }} {{ item.publish_end | formatDate }}
        </span>
        <span v-if="willStartSoon && getEndDayKey() != 'ended'" class="has-text-weight-bold">{{ $t('soon') }}</span>
      </div>
      <div class="record-title">
        <p class="has-text-small">{{ $t(`procedure.type.${item.category_type}`) }}</p>
        <a class="is-size-4 has-text-weight-bold" @click="goToProcedure(item)">
          {{ getProcedureTitle() }}
        </a>
      </div>
      <div class="is-flex is-flex-space-between">
        <div>
          <v-clamp v-if="item.area" autoresize :max-lines="1"
            >{{ item.area }} {{ item.sub_area ? ' - ' + item.sub_area : '' }}</v-clamp
          >
          <v-clamp v-if="item.faculty_title" class="has-text-small" autoresize :max-lines="1"
            >{{ item.faculty_title }} {{ item.department_title ? ' - ' + item.department_title : '' }}</v-clamp
          >
        </div>
        <div v-if="item.application && item.application.status" class="has-margin-top">
          <b-button
            type="is-black"
            outlined
            size="is-small"
            @click="$router.push('/application/' + item.code + '/view')"
          >
            <faicon icon="cube"></faicon>
            <span class="is-hidden-mobile">{{ $t('applicationState.start') }}</span></b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ProcedureService from '@/services/ProcedureService'

export default {
  props: {
    item: Object,
  },
  computed: {
    organization() {
      return this.$route.query.org
    },
    willStartSoon() {
      const today = dayjs().format('YYYY-MM-DD')
      const publishedDR = dayjs(String(this.item.published_dr_date)).format('YYYY-MM-DD')
      const publishStart = dayjs(String(this.item.publish_start)).format('YYYY-MM-DD')
      return Boolean(publishedDR && publishStart && publishedDR <= publishStart && publishStart > today)
    },
  },
  methods: {
    getEndDayKey() {
      const today = dayjs().format('YYYY-MM-DD')
      const tomorrow = dayjs(today)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      const end = dayjs(String(this.item.publish_end)).format('YYYY-MM-DD')

      if (end == today) {
        return 'today'
      } else if (end == tomorrow) {
        return 'tomorrow'
      } else if (end < today) {
        return 'ended'
      } else {
        return 'other'
      }
    },
    getProcedureTitle() {
      return ProcedureService.helpers.getProcedureTitle(this.item)
    },
    goToProcedure(item) {
      this.$router.push({ name: 'procedure', params: { code: item.code }, query: { org: this.organization } })
    },
  },
}
</script>
